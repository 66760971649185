@import "../../_globalColor";

.main {
  width: 90%;
  padding: 20px 10px;
  margin: 0px auto;
  margin-top: 4rem;
}

.greet-main {
  width: 90%;
  padding: 20px 10px;
  margin: 0px auto;
  margin-top: 2rem;
}

.greeting-main {
  display: flex;
  gap: 40px;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}

.subTitle {
  color: $subTitle !important;
}

.greeting-main > * {
  flex: 1;
  margin-bottom: 30px;
}

.empty-div {
  margin-bottom: 40px;
}

.button-greeting-div {
  display: flex;
  margin-top: 40px;
  gap: 30px;
}

.download-link-button {
  text-decoration: none;
}

.greeting-text {
  font-size: 70px;
  line-height: 1.1;
  color: $textColor !important;
}

.greeting-text-p {
  font-size: 30px;
  line-height: 40px;
}

/* Image Container */
.greeting-image-div,
.greeting-image-div-dark {
  width: 30%;
  max-width: 750px;
  margin: 0 auto;
}

/* Image Styles */
.greeting-image-div > img,
.greeting-image-div-dark > img {
  width: 100%;
  max-width: 100%;
  height: auto;
  aspect-ratio: 1/1;
  object-fit: cover;
  border-radius: 50%;
  border: 7px solid #55198b;
  box-shadow: 0 0.5rem 1rem $lightBoxShadowDarker;
}

.greeting-image-div-dark > img {
  box-shadow: 0 0 0.8rem 0.3rem rgba($darkBoxShadow, 0.2);
}

.wave-emoji {
  animation-duration: 1.8s;
  animation-iteration-count: infinite;
  animation-name: wave;
  display: inline-block;
  transform-origin: 70% 70%;
}

@keyframes wave {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(-10deg);
  }
  20% {
    transform: rotate(12deg);
  }
  30% {
    transform: rotate(-10deg);
  }
  40% {
    transform: rotate(9deg);
  }
  50% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

/* Responsive Breakpoints */
@media (max-width: 1380px) {
  .greeting-text {
    font-size: 50px;
  }
  .greeting-text-p {
    font-size: 20px;
  }
}

@media (max-width: 992px) {
  .greeting-image-div,
  .greeting-image-div-dark {
    width: 40%;
  }
}

@media (max-width: 768px) {
  .greeting-main {
    flex-direction: column;
    min-height: calc(100vh - 100px);
    text-align: center;
    gap: 20px;
  }

  .greeting-text-div {
    width: 100%;
    flex: 0 1 auto;
  }

  .greeting-image-div,
  .greeting-image-div-dark {
    width: 65vw;
    height: 65vw;
    max-width: 300px;
    max-height: 300px;
    margin: 10px 0;
  }

  .greeting-image-div > img,
  .greeting-image-div-dark > img {
    width: 100%;
    height: 100%;
    max-width: none;
  }

  .button-greeting-div {
    flex-direction: column;
    align-items: center;
    gap: 20px;
    margin: 20px 0;
    padding: 0 20px;
  }
}

@media (max-width: 480px) {
  .greeting-text {
    font-size: 40px;
  }
  
  .greeting-text-p {
    font-size: 18px;
    line-height: 30px;
  }
}

@media (max-width: 320px) {
  .main {
    width: auto;
  }
  
  .button-greeting-div {
    gap: 15px;
  }
}