@import "../../_globalColor";

.contact-div-main {
  display: flex;
}

.dark-mode a {
  color: $textColorDark;
}

.contact-div-main > * {
  flex: 1;
}

.contact-title {
  font-size: 65px;
  font-weight: 400;
}

.contact-detail,
.contact-detail-email {
  margin-top: 20px;
  font-size: 40px;
  font-weight: 400;
  color: $subTitle;
  text-decoration: none;
  opacity: 1;
  transition: opacity 0.3s ease;
}

.contact-detail:hover,
.contact-detail-email:hover {
  opacity: 0.5;
}

.contact-subtitle {
  text-transform: uppercase;
  font-size: 25px;
}

.contact-text-div {
  margin-top: 1.5rem;
}

.contact-margin-top {
  margin-top: 4rem;
}

.contact-image-div {
  // Limit the maximum size of the Lottie animation
  max-width: 800px;
  margin-left: 1.5rem;
  margin-top: -4rem;
  
  // Ensure the animation stays within bounds
  & > div {
    max-width: 100%;
    height: auto !important;
  }
}

@media (max-width: 1200px) {
  .contact-image-div {
    display: none;
  }
}

@media (max-width: 939px) {
  .contact-title {
    font-size: 56px;
    font-weight: 400;
    text-align: center;
  }
  
  .contact-subtitle {
    font-size: 16px;
    line-height: normal;
    text-align: center;
  }
  
  .contact-text-div {
    text-align: center;
    margin-top: 1.5rem;
  }
  
  .contact-detail,
  .contact-detail-email {
    font-size: 20px;
  }
}

@media (max-width: 768px) {
  .contact-title {
    font-size: 40px;
  }
}