html.splash-active,
body.splash-active {
  overflow: hidden !important;
  height: 100%;
  width: 100%;
  position: fixed;
}

.splash-container {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  min-height: 100vh;
  width: 100%;
}

.splash-title-container {
  font-size: 2.5rem;
  text-decoration: none;
  white-space: nowrap;
  padding: 0 20px;
  display: flex;
  justify-content: center;
}

.splash-title {
  font-family: "Agustina", cursive;
  font-weight: bold;
  font-variant-ligatures: no-common-ligatures;
  -webkit-font-variant-ligatures: no-common-ligatures;
  padding: 0 10px;
}

/* Responsive adjustments */
@media (max-width: 400px) {
  .splash-title-container {
    font-size: 2rem;
  }
}

@media (max-width: 350px) {
  .splash-title-container {
    font-size: 1.5rem;
  }
  .splash-title {
    font-weight: 300;
  }
}