@import "../../_globalColor";

/* React-headroom */
.headroom-wrapper {
  max-height: 100px;
}

/* Header */
.dark-menu {
  background-color: $darkBackground !important;
  color: $textColorDark;
}

.dark-menu li a {
  color: $textColorDark !important;
}

.dark-menu li a:hover {
  background-color: $buttonColor !important;
}

.header {
  background-color: $lightBackground1;
  max-width: 100%;
  padding: 15px 20px;
  margin: 0px auto;
}

.header ul {
  margin: 0;
  padding: 0;
  list-style: none;
  overflow: hidden;
}

.grey-color {
  color: $subTitle;
}

.header li a {
  display: block;
  padding: 15px 20px;
  color: $textColor;
  text-decoration: none;
}

.header li a:hover,
.header .menu-btn:hover {
  background-color: $headerHoverBG;
}

.header .logo {
  display: block;
  flex: none;
  float: left;
  font-size: 1.5em;
  text-decoration: none;
  margin-top: 10px;
  line-height: normal;
  transition: opacity 0.3s ease;
}

.header .logo-name {
  font-family: "Agustina";
  font-weight: bold;
  font-variant-ligatures: no-common-ligatures;
  -webkit-font-variant-ligatures: no-common-ligatures;
  color: #55198b;
  padding: 0 10px;
}

.header .logo-name-dark {
  font-family: "Agustina";
  font-weight: bold;
  font-variant-ligatures: no-common-ligatures;
  -webkit-font-variant-ligatures: no-common-ligatures;
  color: #FFFFFF;
  padding: 0 10px;
}

/* Menu */
.header .menu {
  clear: both;
  max-height: 0;
  transition: max-height 0.2s ease-out;
}

/* Menu icon */
.header .menu-icon {
  cursor: pointer;
  float: right;
  padding: 28px 20px;
  position: relative;
  user-select: none;
}

.header .menu-icon .navicon {
  background: $iconBackground;
  display: block;
  height: 2px;
  position: relative;
  transition: background 0.2s ease-out;
  width: 18px;
}

.header .menu-icon .navicon-dark {
  background: $textColorDark;
}

.header .menu-icon .navicon:before,
.header .menu-icon .navicon:after {
  background: $iconBackground;
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  transition: all 0.2s ease-out;
  width: 100%;
}

.header .menu-icon .navicon-dark:before,
.header .menu-icon .navicon-dark:after {
  background: $textColorDark !important;
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  transition: all 0.2s ease-out;
  width: 100%;
}

.header .menu-icon .navicon:before {
  top: 5px;
}

.header .menu-icon .navicon:after {
  top: -5px;
}

/* Menu btn */
.header .menu-btn {
  display: none;
}

.header .menu-btn:checked ~ .menu {
  max-height: 486px;
}

.header .menu-btn:checked ~ .menu-icon .navicon {
  background: transparent !important;
}

.header .menu-btn:checked ~ .menu-icon .navicon:before {
  transform: rotate(-45deg);
}

.header .menu-btn:checked ~ .menu-icon .navicon:after {
  transform: rotate(45deg);
}

.header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:before,
.header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:after {
  top: 0;
}

/* Desktop styles (min-width: 940px) */
@media (min-width: 940px) {
  .header li {
    float: left;
  }
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .header .menu {
    max-height: none;
  }
  .header .menu-icon {
    display: none;
  }
}

/* Mobile optimizations (max-width: 939px) */
@media (max-width: 939px) {
  /* Slimmer header padding */
  .header {
    padding: 10px 15px;
  }
  
  /* Compact menu icon */
  .header .menu-icon {
    padding: 18px 15px;
  }
  
  /* Adjusted logo margin */
  .header .logo {
    margin-top: 5px;
  }
  
  /* Optional: Smaller hamburger icon */
  .header .menu-icon .navicon {
    height: 1.5px;
    width: 16px;
  }
  .header .menu-icon .navicon:before,
  .header .menu-icon .navicon:after {
    height: 1.5px;
  }
  .header .menu-icon .navicon:before {
    top: 4px;
  }
  .header .menu-icon .navicon:after {
    top: -4px;
  }
}

/* Logo Font Size Adjustments */
@media (max-width: 990px) {
  .header .logo {
    font-size: 1.25em;
  }
  .header .logo-name,
  .header .logo-name-dark {
    font-weight: 300;
  }
}

@media (max-width: 350px) {
  .header .logo {
    font-size: 1em;
  }
}